<template>
  <CCard>
    <CCardHeader>
      {{ $t("Users") }}
      <div class="card-header-actions">
        <CButton
          color="primary"
          v-if="isAdministrator"
          :to="{ name: 'User', params: { id: 'new' } }"
        >
          <i class="fa fa-plus" />
          {{ $t("Create") }}
        </CButton>
      </div>
    </CCardHeader>

    <CCardBody>
      <tableComponent
        :columns="['email', 'created_at', 'roles', 'actions']"
        :filter-exclude-columns="['created_at', 'actions']"
        ref="table"
        :data="usersData"
        v-if="roles.length"
      >
        <!-- <template slot="firstname" slot-scope="{ row }">
            {{ row.firstname || '' }}
          </template>
          <template slot="lastname" slot-scope="{ row }">
            {{ row.lastname || '' }}
          </template> -->
        <template slot="roles" slot-scope="{ row }">
          {{ row.roles.map((r) => $capitalize(r)).join(", ") }}
        </template>
        <template slot="clients" slot-scope="{ row }">
          <v-select
            multiple
            :value="showedClients(row.clients)"
            label="name"
            :options="clients"
            @input="changeClients($event, row.id)"
            data-cy="vselect-client"
            append-to-body
            :calculate-position="withPopper"
          />
        </template>
        <div slot="actions" slot-scope="{ row }" class="d-flex gap-1">
          <CButton
            :to="{ name: 'User', params: { id: row.id } }"
            color="primary"
            variant="outline"
            v-c-tooltip="$t('Details about user')"
          >
            <i class="fa fa-search-plus" />
          </CButton>
          <CButton
            :to="{ name: 'User', params: { id: row.id }, query: { edit: 1 } }"
            color="primary"
            variant="outline"
            v-c-tooltip="$t('Edit user')"
            v-if="isAdministrator"
          >
            <i class="fa fa-edit" />
          </CButton>
          <CButton
            @click="() => deleteUser(row)"
            color="danger"
            variant="outline"
            v-c-tooltip="$t('Delete user')"
            v-if="isAdministrator"
          >
            <i class="fa fa-trash-o" />
          </CButton>
        </div>
      </tableComponent>
    </CCardBody>
  </CCard>
</template>

<script>
import { freeSet } from "@coreui/icons";
import { USER_ENDPOINTS, users } from "@/services/users";
import { ActiveUser } from "@/services/user";
import { CInput } from "@mz/coreui-vue";
import { ListsService } from "@/services/lists";
import "vue-select/dist/vue-select.css";
import { createPopper } from "@popperjs/core";

export default {
  name: "users",
  freeSet,
  props: {},
  data() {
    return {
      USER_ENDPOINTS,
      isAdministrator: this.$store.state.role.includes("superuser"),
      roles: [],
      clients: [],
      values: [],
      placement: "top",
      usersData: [],
    };
  },
  mounted() {
    this.fetchRoles();
    this.fetchClients();
    this.fetchUsers();
  },
  computed: {
    tableOverrideFilters() {
      return {
        roles: {
          options: this.roles.map((option) => ({
            label: this.$capitalize(option),
            value: option,
          })),
        },
      };
    },
  },
  methods: {
    async fetchRoles() {
      this.roles = await users.getRoles();
    },
    async fetchUsers() {
      this.usersData = await users.getUsers();
    },

    async fetchClients() {
      var { data, res } = await ListsService.get_list({
        collection: "clients_wo_user",
        filter_off: false,
      });
      if (res.status !== 200) {
        return alert("Please check your internet connetion or log in");
      }
      if (data.success) {
        this.clients = data.data;
        this.values = new Array(this.clients.length).fill([]);
      }
    },

    async deleteUser(user) {
      if (!(await confirm(this.$t("user_delete", { email: user.email })))) {
        return;
      }
      try {
        await users.deleteUser(user.id);
      } catch (e) {}
      this.$refs.table.$refs.table.getData();
    },

    rowIndex(row) {
      return this.clients
        .map(function (e) {
          return e.id;
        })
        .indexOf(row.id);
    },

    showedClients(cli_ids) {
      if (cli_ids) {
        return cli_ids.map((v) => this.clients.filter((c) => v === c.id)[0]);
      } else {
        return [];
      }
    },

    async changeClients(event, userid) {
      var data = await users.editClients(
        userid,
        event.map((a) => a.id)
      );
      if (data.success) {
        this.$refs.table.$refs.table.getData();
      }
    },

    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width;

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -1],
            },
          },
          {
            name: "toggleClass",
            enabled: true,
            phase: "write",
            fn({ state }) {
              component.$el.classList.toggle(
                "drop-up",
                state.placement === "top"
              );
            },
          },
        ],
      });
      return () => popper.destroy();
    },
  },
  components: { CInput },
};
</script>

<style scoped lang="scss">
.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement="top"] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
</style>
